import React from "react"
import notfound from "../images/svg/404.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { NotFoundContainer, GetInTouch } from "../styles/common"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found || x-hub.io" />
    <NotFoundContainer>
      <img src={notfound} alt="page not found" />
      <p>We think you get lost</p>
      <GetInTouch small reverse to="/">
        <span />
        <span />
        <span />
        <span />
        Home Page
      </GetInTouch>
    </NotFoundContainer>
  </Layout>
)

export default NotFoundPage
